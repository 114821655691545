import React from "react";
import PropTypes from "prop-types";
import {Link} from "gatsby";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const BottomRow = ({ page: { config: { bottomRow }}}) => {
  return (
    <Column className={css.bottomRow} direction="cc">
      <Header primaryText={bottomRow.headingPrimary} secondaryText={bottomRow.headingSecondary}/>
      <p>{bottomRow.text}</p>
      <p>For further information please see visit our frequently asked questions section <Link to={"/faqs"}>here...</Link></p>
    </Column>
  );
};

BottomRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default BottomRow;