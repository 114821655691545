import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const MiddleRowTwo = ({ page: { config: { middleRowTwo }}}) => {
  
  const renderPrices = list => {
    return list.sort((a, b) => a.order - b.order)
      .map((item, i) => {
        return (
          <tr key={i}>
            <td>{item.vehicleClass}</td>
            <td className={css.hidden}>€{item.testFee.toFixed(2)}</td>
            <td className={css.hidden}>€{item.levy.toFixed(2)}</td>
            <td>€{(Math.round((item.testFee + item.levy) * 100) / 100).toFixed(2)}</td>
            <td>€{item.retestFee.toFixed(2)}</td>
          </tr>
        );
      });
  };
  
  return (
    <Column className={css.middleRow} direction="cc">
      <Header primaryText={middleRowTwo.headingPrimary} secondaryText={middleRowTwo.headingSecondary}/>
      <table>
        <thead>
        <tr>
          <td>Class of Vehicle</td>
          <td className={css.hidden}>Test Fee</td>
          <td className={css.hidden}>RSA Levy</td>
          <td>Test Fee + Levy</td>
          <td>Retest Fee</td>
        </tr>
        </thead>
        <tbody>
        {renderPrices(middleRowTwo.prices)}
        </tbody>
      </table>
    </Column>
  );
};

MiddleRowTwo.propTypes = {
  page: PropTypes.object.isRequired
};

export default MiddleRowTwo;