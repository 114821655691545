import React from "react";
import PropTypes from "prop-types";

import Column from "src/components/Column";
import Header from "src/components/Header";

import css from "./index.module.scss";


const MiddleRowOne = ({ page: { config: { middleRowOne }}}) => {
  return (
    <Column className={css.middleRow} direction="cc">
      <span id="pricing"/>
      <Header primaryText={middleRowOne.headingPrimary} secondaryText={middleRowOne.headingSecondary}/>
      <p>{middleRowOne.textOne}</p>
      <p>{middleRowOne.textTwo}</p>
    </Column>
  );
};

MiddleRowOne.propTypes = {
  page: PropTypes.object.isRequired
};

export default MiddleRowOne;