import React from "react";

import Column from "src/components/Column";
import {TopRow, MiddleRowOne, MiddleRowTwo, BottomRow} from "src/components/pages/TestCentre";
import TestCentreNavigation from "src/containers/TestCentreNavigation";
import {withSEO} from "src/hoc/SEO";
import { pages } from "src/config";


const TestCentrePage = props => {
  
  const page = pages.testCentre;
  
  return (
    <main>
      <Column direction="cc">
        <TestCentreNavigation page={page} location={props.location.pathname}/>
        <TopRow page={page}/>
        <MiddleRowOne page={page}/>
        <MiddleRowTwo page={page}/>
        <BottomRow page={page}/>
      </Column>
    </main>
  );
};

export default withSEO({
  title: pages.testCentre.title
})(TestCentrePage);
