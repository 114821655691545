import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";

import ImageCVRTRow from "src/containers/ImageCVRTRow";


const TopRow = ({ page })  => {
  return (
    <StaticQuery
      query={graphql`
        query {
          busy: file(relativePath: { eq: "images/test-centre/busy.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => <ImageCVRTRow image={data.busy.childImageSharp.fluid}/>}
    />
  );
};

TopRow.propTypes = {
  page: PropTypes.object.isRequired
};

export default TopRow;